.collages-container {
  margin-top: 0px;
}

.collage-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.collage-section-1 {
  background-color: #b00808;
}

.collage-section-2 {
  background-color: #BCC845;
}

.collage-section-3 {
  background-color: blue;
}

.collage-section-4 {
  background-color: #542864;
}

.collage-section-5 {
  background-color: #F8B719;
}

.collage-image {
  width: 35%;
}

.collage-title {
  color: white;
  margin-top: 10px;
  font-family: didot-regular;
  font-size: 14px;
  width: 35%;
  text-align: right;
}