@font-face {
  src: url('./fonts/Calcio-Demo.ttf');
  font-family: calcio;
}

@font-face {
  src: url('./fonts/Didot\ Regular.ttf');
  font-family: didot-regular;
}

@font-face {
  src: url('./fonts/Didot\ LT\ Std\ Bold.otf');
  font-family: didot-bold;
}

@font-face {
  src: url('./fonts/Inter-SemiBold.ttf');
  font-family: inter-semibold;
}

@font-face {
  src: url('./fonts/CanelaCondensed-Medium-Trial.otf');
  font-family: canela-medium-condensed;
}

@font-face {
  src: url('./fonts/CanelaDeck-Medium-Trial.otf');
  font-family: canela-medium-deck;
}

@font-face {
  src: url('./fonts/Canela-Medium-Trial.otf');
  font-family: canela-medium-text;
}

html {
  background-color: white;
}

/* .app-container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
} */

.header-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 15%;
  padding-left: 15%;
  box-sizing: border-box;
  padding-top: 30px;
  padding-bottom: 30px;
}

.header-container a {
  color: inherit;
  text-decoration: none;
}

.header-left {
  width: 50%;
}

.header-right {
  width: 50%;
  align-self: center;
}

.header-left-name {
  font-family: calcio;
  font-size: 34px;
  cursor: pointer;
  transition: ease all 0.2s;
}

.header-left-name:hover {
  opacity: 0.7;
}

.header-right-flex {
  align-self: center;
  float: right;
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.header-right-link {
  font-family: didot-regular;
  font-size: 17px;
  cursor: pointer;
  transition: ease all 0.2s;
  z-index: 999999;
}

.header-right-link:hover {
  opacity: 0.7;
}

.header-active-link {
  text-decoration: underline;
}

.main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 130px;
  margin-bottom: 50px;
}

.main-list {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 150px;
}

.main-list-item {
  align-self: center;
  font-family: canela-medium-text;
  font-size: 24px;
  cursor: pointer;
  user-select: none;
  z-index: 999999;
  padding: 3px 5px 0px 5px;
  line-height: 24px;
  /* background: black; */
  color: black;
  mix-blend-mode: screen;
  text-shadow:
    -1px -1px 0 #fff,
     0   -1px 0 #fff,
     1px -1px 0 #fff,
     1px  0   0 #fff,
     1px  1px 0 #fff,
     0    1px 0 #fff,
    -1px  1px 0 #fff,
    -1px  0   0 #fff;
}

.background-container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  text-align: center;
  z-index: -999;
  padding-top: 229px;
  pointer-events: none;
  /* padding-top: 150px; */
}

.background-list {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 50px;
}

.background-list-item {
  align-self: center;
  font-family: canela-medium-text;
  font-size: 24px;
  cursor: pointer;
  user-select: none;
  z-index: 999999;
  padding: 3px 5px 0px 5px;
  line-height: 24px;
  color: black;
}

.item-with-image {
  color: white;
  text-shadow: none;
}

.hover-images-container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 0;
  pointer-events: none;
}

.hover-image {
  position: absolute;
  min-width: 400px;
  max-width: 600px;
  max-height: 600px;
  transform: translateY(-50%) translateX(-50%);
}

.images-preload {
  display: none;
}