@media screen and (max-width: 820px) {
	.header-container {
		flex-direction: column;
		justify-content: center;
		gap: 20px;
		padding-top: 50px;
	}
	.header-left {
		width: 100%;
		display: flex;
		justify-content: center;
	}
	.header-right {
		width: 100%;
	}
	.header-right-flex {
		justify-content: center;
		float: none;
		gap: 20px;
	}
	.main-container {
		padding-top: 80px !important;
	}
  .background-container {
    padding-top: 238px !important;
  }
  .collage-section {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .collage-image {
    width: 70% !important;
  }
  .collage-title {
    width: 70% !important;
  }
  .oil-section {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .oil-image {
    width: 70% !important;
  }
  .oil-title {
    width: 70% !important;
  }
  .headshot-image {
    width: 225px !important;
  }
}

@media screen and (max-width: 600px) {
	.header-right-flex {
		gap: 15px;
	}
	.main-container {
		padding-top: 50px !important;
	}
  .background-container {
    padding-top: 208px !important;
  }
}

@media screen and (max-height: 950px) {
	.main-container {
    padding-top: 120px;
  }
  .background-container {
    padding-top: 219px;
  }
}

@media screen and (max-height: 900px) {
	.main-container {
    padding-top: 100px;
  }
  .background-container {
    padding-top: 199px;
  }
}

@media screen and (max-height: 800px) {
	.main-container {
    padding-top: 80px;
  }
  .background-container {
    padding-top: 179px;
  }
}

@media screen and (max-height: 750px) {
	.main-container {
    padding-top: 60px;
  }
  .background-container {
    padding-top: 159px;
  }
}

@media screen and (max-height: 700px) {
	.main-container {
    padding-top: 40px;
  }
  .background-container {
    padding-top: 139px;
  }
}