.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}

.headshot-image {
  width: 300px;
  margin-top: 80px;
}

.contact-item {
  font-family: didot-regular;
}

.contact-item a {
  color: inherit;
  text-decoration: none;
}