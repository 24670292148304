.oil-container {
  margin-top: 0px;
}
.oil-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.oil-section-1 {
  background-color: #000000;
}

.oil-image {
  width: 35%;
}

.oil-title {
  color: white;
  margin-top: 10px;
  font-family: didot-regular;
  font-size: 14px;
  width: 35%;
  text-align: right;
}